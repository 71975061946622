import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

interface PayNowButtonProps {
  contactIdentifier: string | undefined | null,
  projectInvoiceIdentifier: string | undefined
}

export function PayNowButton({ contactIdentifier, projectInvoiceIdentifier }: PayNowButtonProps): JSX.Element {
  const navigate = useNavigate();
  return (
    <Button
      bg='orange.600'
      color='white'
      fontWeight='bold'
      size='sm'
      height='40px'
      width='140px'
      _hover={{ bg: 'orange.700' }}
      _active={{ bg: 'orange.800' }}
      onClick={() => {
        navigate(`/portal/pay-invoice/${contactIdentifier}/${projectInvoiceIdentifier}/`);
      }}
    >
      Pay now
    </Button>
  );
}
