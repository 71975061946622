import {
  Box, Center, Flex, FlexProps, Heading, Img, Table, TableContainer, Tbody, Td, Th, Thead, Tr, chakra,
} from '@chakra-ui/react';
import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  Table as ReactTable,
  Row,
  RowSelectionState,
  createColumnHelper,
  flexRender, getCoreRowModel, getFilteredRowModel, useReactTable,
} from '@tanstack/react-table';
import { Select } from 'chakra-react-select';
import { property, startCase } from 'lodash';
import {
  useCallback, useMemo, useState,
} from 'react';
import { When } from 'react-if';

import RowState from './RowState';
import FilterIcon from 'assets/icons/filter.svg';
import QuerySuspense from 'components/QuerySuspense';
import { useTableData } from 'hooks/useTableData';

function getUniqueColumnValues({ column }: { column: any }) {
  const { enableFilter, filterAccessorKey, placeholder } = column.columnDef.meta ?? {};

  if (!enableFilter || filterAccessorKey === undefined || placeholder === undefined) return [];

  const allValues = column.getFacetedRowModel().rows.map((row: any) => row.original[filterAccessorKey]);
  const uniqueValuesSet = new Set<string>(allValues);
  const uniqueColumnValues = Array.from(uniqueValuesSet);

  return uniqueColumnValues;
}
interface FilterProps extends FlexProps {
  column: any
  index: number
}

function Filter({ column, index, ...props }: FilterProps) {
  const uniqueColumnValues = useMemo(() => getUniqueColumnValues({ column }), [column]);

  if (uniqueColumnValues.length === 0) return null;

  const options = [{ value: '', label: 'All' }, ...uniqueColumnValues.map((value) => ({
    value,
    // Filters can contain email values, so we need to check before formatting values
    label: value?.includes('@') ? value : startCase(value),
  }))];

  return (
    <Flex direction='row' alignItems='center' {...props}>
      { column.columnDef.meta?.filterHasIcon && (
      <Box mr='20px' alignItems='center'>
        <Img height='25px' src={FilterIcon} />
      </Box>
      )}

      <Box width='260px'>
        <Select
          useBasicStyles
          placeholder={column.columnDef.meta?.placeholder}
          onChange={(selectedOption) => column.setFilterValue(selectedOption?.value)}
          options={options}
        />
      </Box>
    </Flex>
  );
}

function StudiumTableAsyncWrapper({
  apiPath,
  queryKeys,
  columns,
  stateKey,
  invisibleColumns,
  handleRowClick,
  emptyTableState,
  variant,
  disablePagination = false,
}: {
  apiPath: string,
  columns: ColumnDef<any, any>[],
  stateKey?: string,
  queryKeys: string[],
  invisibleColumns?: Record<string, boolean>,
  handleRowClick?: (row: Row<any>, table?: ReactTable<any>, e?: React.MouseEvent<any>) => void,
  emptyTableState: {
    message: string,
    icon: string,
  },
  variant?: string,
  disablePagination?: boolean
}): JSX.Element {
  const {
    queryData,
    PaginationControll,
  } = useTableData<any>({ path: apiPath, disablePagination, queryKeys: queryKeys ?? [] });

  return (
    <QuerySuspense queries={[queryData]}>
      <StudiumTableSynchronous
        columns={columns}
        stateKey={stateKey}
        invisibleColumns={invisibleColumns}
        handleRowClick={handleRowClick}
        emptyTableState={emptyTableState}
        PaginationControll={PaginationControll}
        rows={queryData.data}
        variant={variant}
      />
    </QuerySuspense>
  );
}

export function StudiumTableSynchronous({
  columns,
  stateKey,
  invisibleColumns,
  handleRowClick,
  emptyTableState,
  PaginationControll,
  rows,
  variant,
}: {
  columns: ColumnDef<any, any>[],
  stateKey?: string,
  invisibleColumns?: Record<string, boolean>,
  handleRowClick?: (row: Row<any>, table?: ReactTable<any>, e?: React.MouseEvent<any>) => void,
  emptyTableState: {
    message: string,
    icon: string,
  },
  PaginationControll: JSX.Element | null,
  rows: any,
  variant?: string,
}): JSX.Element {
  function getColumns(): ColumnDef<any, any>[] {
    if (!stateKey) return columns;

    const columnHelper = createColumnHelper();
    const stateAccessorColumn = columnHelper.accessor(property(stateKey), {
      cell: (info: CellContext<unknown, string | null>) => RowState(info.getValue()),
      header: () => '',
      id: 'State',
    }) as ColumnDef<any, any>;

    return [stateAccessorColumn, ...columns];
  }

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    [],
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    columns: getColumns(),
    data: rows.data || [],
    onColumnFiltersChange: setColumnFilters,
    filterFns: {},
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    state: {
      rowSelection,
      columnFilters,
      columnVisibility: invisibleColumns,
    },
  });

  const isNewState = useCallback((row: Row<any>) => {
    if (!stateKey) return false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return row.original[stateKey] === 'new';
  }, [stateKey]);

  const rowModel = table.getRowModel();

  function onRowClick(row: Row<any>, e: React.MouseEvent<any, any>): void {
    row.getToggleSelectedHandler()(e);
    if (handleRowClick) handleRowClick(row, table, e);
  }

  return (
    <>
      <TableContainer mt='20px'>
        {table.getHeaderGroups().map((headerGroup) => (
          <Flex key={headerGroup.id} gap='25px'>
            {headerGroup.headers.map((header, index) => (
              <Filter index={index} mb='35px' mt='14px' key={header.id} column={header.column} />
            ))}
          </Flex>
        ))}
        <Table size='sm' variant={variant}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                  >
                    <Box maxW='150px' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {rowModel.rows.map((row) => (
              <Tr
                padding='none'
                key={row.id}
                role='group'
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    py='5px'
                    key={cell.id}
                    backgroundColor={isNewState(row) ? 'gray.800' : 'transparent'}
                    textOverflow={cell.column.id === 'Actions' ? 'none' : 'ellipsis'}
                    onClick={cell.column.id === 'Actions' ? () => {} : (e) => onRowClick(row, e)}
                    _groupHover={!handleRowClick ? { cursor: 'default' } : {
                      cursor: 'pointer', transition: 'all ease-in-out 0.25s',
                    }}
                    textAlign={cell.column.id === 'Actions' ? 'right' : 'left'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {PaginationControll}
      <When condition={rows.data?.length === 0}>
        <Center flexDirection='column' gap='38px' mx='auto' mt='200px' w='400px'>
          <Img src={emptyTableState?.icon} w='130px' height='110px' opacity='0.4' />
          <Heading as='h3' size='lg' textAlign='center'>{emptyTableState?.message}</Heading>
        </Center>
      </When>
    </>
  );
}

export default chakra(StudiumTableAsyncWrapper);
