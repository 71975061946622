import { addMinutes, format } from 'date-fns';
import pluralize from 'pluralize';
import prettyBytes from 'pretty-bytes';

const dateFormat = 'MMM d, yyyy';
const timeFormat = 'hh:mm aa';
const guidFormatRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export function filesSizeTags(size?: number | bigint | null, count?: number | null): string {
  const prettySize = getFormattedFileSize(size);
  return `${count} ${pluralize('file', count || 0)} | ${prettySize}`;
}

export function getFormattedFileSize(size?: number | bigint | null): string {
  const moreThanGig = Number(size || 0) > 1000000000;

  const prettySize = prettyBytes(Number(size || 0), { maximumFractionDigits: moreThanGig ? 2 : 1, minimumFractionDigits: 1, binary: true });
  return `${prettySize}`;
}

export function formatPercent(percent: number, decimalPlaces = 1): string {
  if (percent === 0) return '0%';
  if (percent === 100) return '100%';

  return `${percent.toFixed(decimalPlaces).toString()}%`;
}

export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export function getDate(date?: string | Date, formatDate = dateFormat): string {
  if (!date) return 'N/A';
  try {
    return format(new Date(date), formatDate);
  } catch (e: any) {
    console.error(`${e.message} [${date}]`);
    return e.message;
  }
}

export function getDuration(startDate: string, duration: number): string {
  const date = new Date(startDate);
  const formattedStartTime = format(date, timeFormat);
  const endTime = addMinutes(date, duration);
  const formattedEndTime = format(endTime, timeFormat);

  return `${formattedStartTime} - ${formattedEndTime}`;
}

export function toIdString(guid: string): string {
  const guidString = guid.replaceAll('-', '');
  return `${guidString.substring(16, 33)}-${guidString.substring(0, 16)}`;
}

export function toGuidString(id: string): string {
  if (guidFormatRegex.test(id)) return id;

  const idParts = id.split('-');
  const rawId = `${idParts[1]}${idParts[0]}`;
  const guidId = rawId.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');

  return guidId;
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function formatCurrency(currency: number): string {
  return USDollar.format(currency);
}
