import {
  Button, Flex, FormLabel as Label, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast,
} from '@chakra-ui/react';
import { useState } from 'react';

import { Transfers } from 'api/transferServerTypes';
import { transferServerApi } from 'api/transfersServerApi';
import DateComponent from 'components/DateComponent';
import AsyncButton from 'components/form/AsyncButton';
import LabelledList from 'components/LabelledList';
import QuerySuspense from 'components/QuerySuspense';
import RecipientCheckBox from 'components/RecipientCheckBox';
import { useApiQuery } from 'hooks/useApiQuery';
import {
  FileDto,
} from 'types/api';
import { logError, parseError } from 'utils/error';

export function ForwardTransferModal({
  transfer,
  files,
  isOpen,
  onClose,
}: {
  transfer: Transfers,
  files: FileDto[],
  isOpen: boolean,
  onClose: () => void,
}): JSX.Element {
  const recipientsQuery = useApiQuery({
    queryKey: [`recipients-${transfer.Project?.ProjectNumber}`],
    queryFn: () => transferServerApi.GET('/api-v2/contacts/{projectNumber}', {
      params: {
        path: {
          projectNumber: transfer.Project?.ProjectNumber!,
        },
      },
    }),
  });
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);

  const toast = useToast();

  async function handleSend(): Promise<void> {
    try {
      if (!selectedRecipients.length) {
        void toast({
          title: 'Cannot Upload',
          description: 'Please select at least one recipient',
          status: 'warning',
        });
        return;
      }
      const selectedFiles = files.length ? files.map((f) => f.FileId) : [];
      await transferServerApi.POST('/api-v2/transfers/{transferId}/copy', {
        params: {
          path: { transferId: transfer.TransferId },
        },
        body: {
          projectId: Number(transfer.Project?.ProjectNumber),
          fileIds: selectedFiles,
          contactIdentifiers: selectedRecipients,
        },
      });
      void toast({
        title: 'Success',
        description: 'Transfer Forward Successfully',
      });
      onClose();
    } catch (err: any) {
      logError(err);
      void toast({
        title: 'Failed to Forward Transfer',
        description: parseError(err).message,
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader height='40px' paddingBottom='none'>
          <Text textStyle='heading3' as='h3' align='left'>Forward</Text>
        </ModalHeader>
        <ModalBody>
          <LabelledList
            title='Transfer Details'
            items={{
              Project: transfer.Project?.ProjectName ?? '',
              Uploader: transfer.CreatedBy ?? '',
              Date: <DateComponent date={transfer.DateCreated ?? new Date()} />,
            }}
          />
          <QuerySuspense queries={[recipientsQuery.queryResult]}>
            <Label>Recipients</Label>
            {recipientsQuery.apiResult?.data?.map((recipient) => (
              <RecipientCheckBox
                key={recipient.ContactIdentifier}
                contact={recipient}
                selectedRecipients={selectedRecipients}
                setSelectedRecipients={setSelectedRecipients}
              />
            ))}
          </QuerySuspense>
        </ModalBody>
        <ModalFooter>
          <Flex direction='row' justifyContent='space-between'>
            <AsyncButton
              spinAfterPromiseResolvedSuccess={false}
              onClick={handleSend}
              w='200px'
              h='52px'
              variant='solid'
              marginRight='2px'
            >
              Send
            </AsyncButton>
            <Button onClick={onClose} w='200px' variant='white' marginLeft='2px'>Close</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
