import {
  Avatar,
  Box,
  Checkbox, Flex, Spacer, Text,
} from '@chakra-ui/react';
import { useState } from 'react';

import { Subscription } from 'api/transferServerTypes';
import { transferServerApi } from 'api/transfersServerApi';
import useAlert from 'hooks/useAlert';

export default function ChannelCheckbox({ subscription }: { subscription: Subscription }): JSX.Element {
  const [subscriptionState, setSubscriptionState] = useState<Subscription>(subscription);
  const { errorToast } = useAlert();
  const handleEmailChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newEmailEnabled = event.target.checked;
    setSubscriptionState((prevState) => ({ ...prevState, EmailEnabled: newEmailEnabled }));

    try {
      const subscriptionUpdateResponse = await transferServerApi.POST('/api-v2/subscriptions/update', {
        body: {
          subscriptionId: subscriptionState.Id,
          emailEnabled: newEmailEnabled,
        },
      });

      setSubscriptionState(subscriptionUpdateResponse.data!);
    } catch (error) {
      errorToast(`Failed to update: ${subscriptionState.Resource.Name}`);
    }
  };

  const handleWebChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newWebEnabled = event.target.checked;
    setSubscriptionState((prevState) => ({ ...prevState, WebEnabled: newWebEnabled }));

    try {
      const subscriptionUpdateResponse = await transferServerApi.POST('/api-v2/subscriptions/update', {
        body: {
          subscriptionId: subscriptionState.Id,
          webEnabled: newWebEnabled,
        },
      });

      setSubscriptionState(subscriptionUpdateResponse.data!);
    } catch (error) {
      errorToast(`Failed to update: ${subscriptionState.Resource.Name}`);
    }
  };

  return (
    <Box border='solid 1px #4A5568' w='900px' borderRadius='15px' color='white' height='100px' display='flex' alignItems='center'>
      <Flex direction='row' align='center' w='818px' justifyContent='space-between'>
        <Flex align='center' gap='4' marginLeft='40px'>
          <Avatar name={subscriptionState.Resource.Name} borderColor='transparent' key={subscriptionState.Resource.Name} />
          <Text>{subscriptionState.Resource.Name}</Text>
        </Flex>
        <Box display='flex' flexDir='row' marginRight='40px' gap='170px'>
          <Flex dir='row'>
            <Checkbox
              isChecked={subscriptionState.WebEnabled}
              onChange={handleWebChange}
            />
          </Flex>
          <Flex dir='row'>
            <Checkbox
              isChecked={subscriptionState.EmailEnabled}
              onChange={handleEmailChange}
            />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
