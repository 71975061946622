import {
  Checkbox, Flex, Img, LightMode,
} from '@chakra-ui/react';
import { Dispatch } from 'react';

import ContactDisplayName from './ContactDisplayName';
import PhoneIcon from 'assets/icons/phone.svg';
import { ProjectContactWithDetailsDto } from 'types/api';

export default function RecipientCheckBox({
  contact,
  selectedRecipients,
  setSelectedRecipients,
}: {
  contact: ProjectContactWithDetailsDto,
  selectedRecipients: string[],
  setSelectedRecipients: Dispatch<React.SetStateAction<string[]>> }): JSX.Element {
  function handleChange(): void {
    const isContactRecipient = selectedRecipients.includes(contact.ContactIdentifier);
    if (isContactRecipient) {
      setSelectedRecipients((prev) => prev.filter((recipient) => recipient !== contact.ContactIdentifier));
    } else {
      setSelectedRecipients((prev) => [...prev, contact.ContactIdentifier]);
    }
  }

  const hasPhoneNumber = contact.MobileTelephone;
  return (
    <Flex alignItems='center'>
      <LightMode>
        <Checkbox type='checkbox' spacing='10px' colorScheme='red' checked={selectedRecipients.includes(contact.ContactIdentifier)} onChange={handleChange}>
          <Flex alignItems='center'>
            <ContactDisplayName {...contact} />
            {hasPhoneNumber && <Img color='white' height='20px' width='20px' src={PhoneIcon} ml='2' />}
          </Flex>
        </Checkbox>
      </LightMode>
    </Flex>
  );
}
