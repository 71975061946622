import {
  Box, Flex, Img, Text,
} from '@chakra-ui/react';
import { When } from 'react-if';

import { PayNowButton } from './PayNowButton';
import CompleteIcon from 'assets/icons/check-circle-green.svg';
import TransferOnHoldIcon from 'assets/icons/transfer-on-hold.svg';
import useUserContext from 'hooks/useUserContext';
import { HoldStatus } from 'types/api';

function getMessage(isUpload: boolean, isStudioMember: boolean | undefined, holdStatus: HoldStatus): string {
  if (isUpload) {
    return holdStatus.uploadDescription;
  }

  return isStudioMember ? holdStatus.studioDescription : holdStatus.clientDescription;
}

function getTitle(isUpload: boolean, holdStatus: HoldStatus): string {
  if (isUpload) {
    if (holdStatus.isOnHold) {
      return 'Transfers Will Be On Hold';
    }
    return 'Transfers Will Not Be On Hold';
  }
  return 'Transfer On Hold';
}

export interface IHoldStatusBannerProps {
  holdStatus: HoldStatus;
  transfer?: {
    Project?: {
      BillingContactIdentifier?: string | null;
    } | null;
  };
  isUpload: boolean,
}

export function HoldStatusBanner({ holdStatus, transfer, isUpload }: IHoldStatusBannerProps): JSX.Element | null {
  const { isStudioMember } = useUserContext();
  if (!holdStatus?.isOnHold && !isUpload) {
    return null;
  }

  const message = getMessage(isUpload, isStudioMember, holdStatus);
  const title = getTitle(isUpload, holdStatus);
  const iconSrc = holdStatus.isOnHold ? TransferOnHoldIcon : CompleteIcon;

  const contactIdentifier = transfer?.Project?.BillingContactIdentifier;
  // This covers the undefined/null case when unpaidInvoice isn't present
  const hasUnpaidInvoice = (holdStatus.unpaidInvoice?.invoiceBalance ?? 0) > 0;
  const canPayInvoice = !isStudioMember && hasUnpaidInvoice && contactIdentifier && !isUpload;

  return (
    <Box
      bg={holdStatus.isOnHold ? 'orange.50' : 'green.50'}
      border='1px solid'
      borderColor={holdStatus.isOnHold ? 'orange.200' : 'green.400'}
      borderRadius='10px'
      p='20px'
      mb='20px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Flex align='center' gap='16px'>
        <Img src={iconSrc} alt='Transfer on Hold' boxSize='24px' />
        <Box>
          <Text fontWeight='bold' fontSize='16px' color={holdStatus.isOnHold ? 'orange.200' : 'green.400'}>
            {title}
          </Text>
          <Text mt='8px' color={holdStatus.isOnHold ? 'orange.700' : 'green.700'} fontSize='14px' lineHeight='20px'>
            {message}
          </Text>
          { hasUnpaidInvoice
            && (
            <Text mt='8px' color='orange.700' fontSize='14px' lineHeight='20px'>
              Unpaid Invoice Balance: $
              {holdStatus.unpaidInvoice!.invoiceBalance!.toFixed(2)}
            </Text>
            )}
        </Box>
      </Flex>
      <When condition={canPayInvoice}>
        <PayNowButton
          contactIdentifier={contactIdentifier}
          projectInvoiceIdentifier={holdStatus.unpaidInvoice?.ProjectInvoiceIdentifier}
        />
      </When>
    </Box>
  );
}
