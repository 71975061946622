import {
  Avatar, Flex, Text, Tooltip,
} from '@chakra-ui/react';

import { transferServerApi } from 'api/transfersServerApi';
import DateIcon from 'assets/icons/date.svg';
import ReviewIcon from 'assets/icons/review.svg';
import SizeIcon from 'assets/icons/size.svg';
import TeamIcon from 'assets/icons/team.svg';
import AuthorizeDisplay from 'components/AuthorizeDisplay';
import DateComponent from 'components/DateComponent';
import { InfoSet } from 'components/Infoset';
import Overview from 'components/Overview';
import QuerySuspense from 'components/QuerySuspense';
import { Roles } from 'contexts/user/claims';
import { useApiQuery } from 'hooks/useApiQuery';
import { filesSizeTags } from 'utils/formatter';

export function ArchiveOverview({ archiveId }: { archiveId?: string | number }): JSX.Element {
  const overviewQuery = useApiQuery({
    queryKey: [`overview-${archiveId}`],
    queryFn: () => transferServerApi.GET('/api-v2/archives/{archiveId}/overview', {
      params: {
        path: {
          archiveId: Number(archiveId),
        },
      },
    }),
    enabled: !!archiveId,
  });
  return (
    <Overview
      header='Archive Overview'
      background='orange.1000'
      before={{
        background: 'orange.600',
      }}
    >
      <QuerySuspense
        spinnerProps={{
          color: 'white',
          m: 0,
          size: 'md',
          thickness: '2px',
        }}
        queries={[overviewQuery.queryResult]}
        mountChildrenDiscrete
      >
        <InfoSet
          label='Date'
          icon={DateIcon}
          value={overviewQuery.apiResult?.data?.date ? <DateComponent date={overviewQuery.apiResult.data.date} /> : undefined}
        />
        <InfoSet
          label='Files'
          icon={SizeIcon}
          value={filesSizeTags(overviewQuery.apiResult?.data?.stats.fileSize, overviewQuery.apiResult?.data?.stats.fileCount)}
        />
        <AuthorizeDisplay role={Roles.StudioMember}>
          <InfoSet
            label='Created by'
            icon={TeamIcon}
            condition={!!overviewQuery.apiResult?.data?.from}
            value={(
              <Flex dir='row' gap='2'>
                <Tooltip label={overviewQuery.apiResult?.data?.from?.email} hasArrow arrowSize={15}>
                  <Avatar size='sm' name={overviewQuery.apiResult?.data?.from?.name} borderColor='transparent' />
                </Tooltip>
              </Flex>
          )}
          />
        </AuthorizeDisplay>
        <InfoSet
          label='Status'
          icon={ReviewIcon}
          value={(
            <Text as='span' whiteSpace='pre-wrap'>
              <Text as='span'>{overviewQuery.apiResult?.data?.status}</Text>
            </Text>
          )}
        />
      </QuerySuspense>
    </Overview>
  );
}
