import { Link, Tooltip } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface LinkWithTooltipProps {
  title: string;
  href: string;
  icon: ReactElement;
  target?: '_blank' | '_self';
  style?: React.CSSProperties;
}

export default function LinkWithTooltip({
  title,
  href,
  icon,
  target = '_blank',
  style,
}: LinkWithTooltipProps): JSX.Element {
  return (
    <Tooltip label={title} placement='top'>
      <Link
        href={href}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          textDecoration: 'none',
          ...style,
        }}
      >
        {icon}
      </Link>
    </Tooltip>
  );
}
