import {
  Box, Img, Text,
} from '@chakra-ui/react';

import NotDueIcon from 'assets/icons/invoice-status-icons/not-due.svg';
import OverdueLongTermIcon from 'assets/icons/invoice-status-icons/overdue-longterm.svg';

interface StatusCardProps {
  title: string;
  displayNumber: string;
  status: 'alert' | 'success';
}

export function BalanceCard({ title, displayNumber, status }: StatusCardProps): React.ReactElement {
  const isAlert = status === 'alert';

  return (
    <Box
      display='flex'
      alignItems='center'
      border='1px solid'
      borderColor='gray.600'
      borderRadius='12px'
      color='white'
      minW='250px'
      mr='15px'
      height='62px'
    >
      <Img src={isAlert ? OverdueLongTermIcon : NotDueIcon} width='20px' height='18px' ml='20px' mr='15px' />
      <Text fontWeight='600' fontSize='18px' pr='20px' lineHeight='22px'>
        {title}: {displayNumber}
      </Text>
    </Box>
  );
}
