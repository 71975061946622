import {
  Box,
  Center,
  Heading,
  Img,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  CellContext,
  ColumnDef,
  ColumnFiltersState,
  Table as ReactTable,
  Row,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { property } from 'lodash';
import { useCallback, useState } from 'react';
import { When } from 'react-if';

import RowState from 'components/table/RowState';

export function StatementTable({
  columns,
  stateKey,
  invisibleColumns,
  handleRowClick,
  emptyTableState,
  PaginationControll,
  rows,
  variant,
}: {
  columns: ColumnDef<any, any>[],
  stateKey?: string,
  invisibleColumns?: Record<string, boolean>,
  handleRowClick?: (row: Row<any>, table?: ReactTable<any>, e?: React.MouseEvent<any>) => void,
  emptyTableState: {
    message: string,
    icon: string,
  },
  PaginationControll: JSX.Element | null,
  rows: any,
  variant?: string,
}): JSX.Element {
  function getColumns(): ColumnDef<any, any>[] {
    if (!stateKey) return columns;

    const columnHelper = createColumnHelper();
    const stateAccessorColumn = columnHelper.accessor(property(stateKey), {
      cell: (info: CellContext<unknown, string | null>) => RowState(info.getValue()),
      header: () => '',
      id: 'State',
    }) as ColumnDef<any, any>;

    return [stateAccessorColumn, ...columns];
  }

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    [],
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    columns: getColumns(),
    data: rows.data || [],
    onColumnFiltersChange: setColumnFilters,
    filterFns: {},
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true,
    state: {
      rowSelection,
      columnFilters,
      columnVisibility: invisibleColumns,
    },
  });

  const isNewState = useCallback((row: Row<any>) => {
    if (!stateKey) return false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return row.original[stateKey] === 'new';
  }, [stateKey]);

  const rowModel = table.getRowModel();

  function onRowClick(row: Row<any>, e: React.MouseEvent<any, any>): void {
    row.getToggleSelectedHandler()(e);
    if (handleRowClick) handleRowClick(row, table, e);
  }

  function getColumnMaxWidth(columnId: string): string {
    if (columnId === 'ProjectName' || columnId === 'ArtistName') {
      return '250px';
    }

    if (columnId === 'Amount' || columnId === 'Balance') {
      return '120px';
    }

    return 'auto';
  }

  return (
    <>
      <TableContainer mt='20px' minWidth='1545px'>
        <Table size='sm' variant={variant}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    style={{ width: `${header.getSize()}px`, maxWidth: `${header.getSize()}px`, minWidth: `${header.getSize()}px` }}
                  >
                    <Box overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {rowModel.rows.map((row) => (
              <Tr
                padding='none'
                key={row.id}
                role='group'
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    py='5px'
                    key={cell.id}
                    maxWidth={getColumnMaxWidth(cell.column.id)}
                    backgroundColor={isNewState(row) ? 'gray.800' : 'transparent'}
                    textOverflow={cell.column.id === 'Actions' ? 'none' : 'ellipsis'}
                    onClick={cell.column.id === 'Actions' ? () => {} : (e) => onRowClick(row, e)}
                    _groupHover={!handleRowClick ? { cursor: 'default' } : {
                      cursor: 'pointer', transition: 'all ease-in-out 0.25s',
                    }}
                    textAlign={cell.column.id === 'Actions' ? 'right' : 'left'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {PaginationControll}
      <When condition={rows.data?.length === 0}>
        <Center flexDirection='column' gap='38px' mx='auto' mt='200px' w='400px'>
          <Img src={emptyTableState?.icon} w='130px' height='110px' opacity='0.4' />
          <Heading as='h3' size='lg' textAlign='center'>{emptyTableState?.message}</Heading>
        </Center>
      </When>
    </>
  );
}
