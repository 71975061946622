import {
  Box, Flex, Heading, Image, Spacer, Stack, Text,
} from '@chakra-ui/react';

import { transferServerApi } from 'api/transfersServerApi';
import EmailIcon from 'assets/icons/email-icon.svg';
import EngineersIcon from 'assets/icons/engineers-icon.svg';
import WebIcon from 'assets/icons/web-icon.svg';
import ChannelCheckbox from 'components/notifications/ChannelCheckbox';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import useUserContext from 'hooks/useUserContext';

export default function EngSubscriptions(): JSX.Element {
  const { user } = useUserContext();
  const subscriptionQuery = useApiQuery({
    queryKey: [user],
    queryFn: () => transferServerApi.GET('/api-v2/subscriptions', {
      params: {
        query: {
          accountId: user!.AccountNumber,
        },
      },
    }),
  });

  return (
    <QuerySuspense queries={[subscriptionQuery.queryResult]}>
      <Box marginLeft='50' marginRight='50'>
        <Stack marginBottom='50px'>
          <Heading fontSize='4xl' as='b'>Subscriptions Settings</Heading>
          <Text>You have the option to select one or both preferences for receiving notifications regarding transfers</Text>
        </Stack>
        <Box bg='gray.800' borderRadius='15' p='35px 45px' w='900px' marginBottom='70px'>
          <Flex direction='row' gap='20'>
            <Flex dir='row' gap='5' alignItems='center'>
              <Image src={WebIcon} w='57px' />
              <Flex direction='column'>
                <Heading fontSize='22px' color='gray.400'>Web Notifications</Heading>
                <Text>Transfers will appear in your Studio Manager inbox, sent, projects and requests</Text>
              </Flex>
            </Flex>
            <Flex dir='row' gap='5' alignItems='center'>
              <Image src={EmailIcon} w='57px' />
              <Flex direction='column'>
                <Heading fontSize='22px' color='gray.400'>Email Notifications</Heading>
                <Text>Transfers will appear in your personal email inbox</Text>
              </Flex>
            </Flex>

          </Flex>
        </Box>
        <Flex justifySelf='left' marginBottom='15px' w='900px' justifyContent='space-between'>
          <Flex dir='row' alignItems='center' gap='3' marginLeft='40px'>
            <Image src={EngineersIcon} w='23px' />
            <Heading fontSize='22px' color='gray.400'>Engineers</Heading>
          </Flex>
          <Box display='flex' gap='100px' marginRight='40px'>
            <Flex dir='row' alignItems='center' gap='3'>
              <Image src={WebIcon} w='23px' />
              <Heading fontSize='22px' color='gray.400'>Web</Heading>
            </Flex>
            <Flex dir='row' alignItems='center' gap='3'>
              <Image src={EmailIcon} w='23px' />
              <Heading fontSize='22px' color='gray.400'>Email</Heading>
            </Flex>
          </Box>
        </Flex>
        <Flex flexDir='column' gap='25px'>
          {subscriptionQuery.apiResult?.data?.map((subscription) => (
            <ChannelCheckbox subscription={subscription} />
          ))}
        </Flex>
      </Box>
    </QuerySuspense>
  );
}
