import {
  Box, Flex, Text,
} from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import { useMemo } from 'react';

import { IWorkOrderDetails } from 'api/interfaces';
import { studioServerApi } from 'api/studioServerApi';
import DateComponent from 'components/DateComponent';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';

interface WorkOrderDropdownProps {
  projectIdentifier: string;
  onSelect: (workOrderId: string) => void;
}

function WorkOrderDropdown({ projectIdentifier, onSelect }: WorkOrderDropdownProps): JSX.Element {
  const workOrdersQuery = useApiQuery({
    queryKey: ['workOrders', projectIdentifier],
    queryFn: () => studioServerApi.GET(
      '/api/projects/{projectIdentifier}/work-orders/display',
      {
        params: {
          path: { projectIdentifier },
        },
      },
    ),
    enabled: !!projectIdentifier,
  });

  const workOrderOptions = useMemo(() => {
    const orders = workOrdersQuery.apiResult?.data as IWorkOrderDetails[] || [];
    const filterOrder = orders.filter((order) => order.Unbillable !== true);

    const sortedOrders = filterOrder.sort((a, b) => {
      const dateA = new Date(a.DateAdded).getTime();
      const dateB = new Date(b.DateAdded).getTime();
      return dateB - dateA;
    });

    return sortedOrders.map((order) => ({
      value: order.WorkOrderIdentifier!,
      label: (
        <Flex alignItems='center' justifyContent='space-between' p={2}>
          <Box>
            <Text fontWeight='bold' color='gray.400'>
              Work Order:
              <Text as='span' color='white'>{` #${order.WorkOrderNumber}`}</Text>
            </Text>
            <Text fontWeight='bold' color='gray.400'>
              Date:
              {' '}
              <Text as='span' color='white'>
                <DateComponent date={order.ScheduledDate} />
              </Text>
            </Text>
            <Text fontWeight='bold' color='gray.400'>
              Part Name:
              <Text as='span' color='white'>{` #${order.PartNumber} ${order.PartName}`}</Text>
            </Text>
            {order.AssignedToContactFullName && (
              <Flex alignItems='center' />
            )}
          </Box>
        </Flex>
      ),
    }));
  }, [workOrdersQuery.apiResult]);

  return (
    <QuerySuspense queries={[workOrdersQuery.queryResult]}>
      <Box>
        <Select
          options={workOrderOptions}
          placeholder='Select a work order...'
          isSearchable={false}
          onChange={(selectedOption: SingleValue<{ value: string }>) => {
            if (selectedOption) {
              onSelect(selectedOption.value);
            }
          }}
          chakraStyles={{
            option: (provided) => ({
              ...provided,
              'border': '0.5px solid grey',
              'borderRadius': 'md',
              'marginBottom': '8px',
              ':hover': {
                backgroundColor: 'gray.600',
              },
            }),
          }}
        />
      </Box>
    </QuerySuspense>
  );
}

export default WorkOrderDropdown;
