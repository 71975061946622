import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';

const baseStyle = {
  table: {
    borderSpacing: '0px 10px',
    borderCollapse: 'separate',
  },
  thead: {
    backgroundColor: 'darkGold.1000',
  },
  tbody: {
    tr: {
      'borderRadius': '5px',
      ':nth-child(even)': {
        backgroundColor: 'inherit',
      },
      ':hover td': {
        backgroundColor: 'gray.600',
      },
    },
    td: {
      'height': '46px',
      'paddingTop': 0,
      'paddingBottom': 0,
      'fontWeight': 500,
      'fontSize': '12px',
      'lineHeight': '20px',
      'borderBottom': 'none',
      'overflow': 'hidden',
      ':last-of-type': {
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      ':first-of-type': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      },
    },
  },
  th: {
    fontFamily: 'Poppins',
    fontWeight: 600,
    lineHeight: '22px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '14px',
    height: '44px',
    py: '13px',
    borderBottom: 'none',
    position: 'relative',
    overflow: 'hidden',

    _first: {
      'borderTopLeftRadius': '8px',
      'borderBottomLeftRadius': '8px',
      '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '8px',
        backgroundColor: 'gold.1000',
      },
    },
    _last: {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
};

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const compact = definePartsStyle(() => {
  const compactStyle = cloneDeep(baseStyle);
  compactStyle.tbody.td.height = '10px';
  return compactStyle;
});

const customStriped = definePartsStyle(() => {
  const customStripedStyle = cloneDeep(baseStyle);
  customStripedStyle.tbody.tr[':nth-child(even)'] = { backgroundColor: '#1A202C' };
  customStripedStyle.tbody.tr[':hover td'] = { backgroundColor: 'inherit' };
  return customStripedStyle;
});

const booking = definePartsStyle(() => {
  // I don't know why but if you overwrite this property it adds a scroll to tables
  const baseStyles = {
    ...baseStyle,
    table: {
      borderSpacing: '0px 10px',
    },
  };

  const bookingStyle = cloneDeep(baseStyles) as any;
  bookingStyle.tbody.tr[':hover td'].backgroundColor = 'none';
  bookingStyle.th.borderBottom = '1px solid #4A5568';
  bookingStyle.th.borderRight = '1px solid #4A5568';
  bookingStyle.th._first = {
    'borderTopLeftRadius': '0px',
    'borderBottomLeftRadius': '0px',
    '::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '9px',
      backgroundColor: 'transparent',
    },
    'borderRight': '1px solid #4A5568',
  };

  bookingStyle.th[':last-of-type'] = {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderRight: 'none',
  };

  bookingStyle.tbody.td = {
    ...bookingStyle.tbody.td,
    paddingTop: '15px',
    paddingBottom: '15px',
    borderRight: '1px solid #4A5568',
  };

  bookingStyle.tbody.td[':last-of-type'] = {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderRight: 'none',
  };

  bookingStyle.tbody.tr.borderBottom = '1px solid #4A5568';

  bookingStyle.tbody.tr[':last-of-type'] = {
    borderBottom: 'none',
  };

  return bookingStyle;
});

const simple = definePartsStyle(() => baseStyle);

export const tableTheme = defineMultiStyleConfig({
  variants: {
    simple,
    compact,
    booking,
    customStriped,
  },
  defaultProps: {
    colorScheme: 'none',
    variant: 'simple',
  },
});
