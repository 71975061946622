import { Box } from '@chakra-ui/react';
import { addDays, differenceInCalendarDays } from 'date-fns';

import { BalanceCard } from './BalanceCard';
import { StatementClient } from 'types/studio-server';
import { formatCurrency } from 'utils/formatter';

export function ClientBalanceCards({ client }: { client: StatementClient }): React.ReactElement {
  interface BalanceSummary {
    openBalance: string;
    overdueBalance: string;
  }

  function getClientBalance(): BalanceSummary {
    if (!client?.Invoices) return { openBalance: formatCurrency(0), overdueBalance: formatCurrency(0) };

    const today = new Date();

    const totals = client.Invoices.reduce(
      (acc, invoice) => {
        if (!invoice.InvoiceDate || invoice.Amount === undefined || invoice.Payments === undefined) {
          return acc;
        }

        const invoiceDate = new Date(invoice.InvoiceDate);
        const netDays = invoice.MajorLabel ? 60 : 30;
        const dueDate = addDays(invoiceDate, netDays);
        const daysDifference = differenceInCalendarDays(today, dueDate);

        const balance = invoice.Amount - invoice.Payments;

        acc.openBalance += balance;
        if (daysDifference > 0) {
          acc.overdueBalance += balance;
        }

        return acc;
      },
      { openBalance: 0, overdueBalance: 0 },
    );

    return {
      openBalance: formatCurrency(totals.openBalance),
      overdueBalance: formatCurrency(totals.overdueBalance),
    };
  }

  const { openBalance, overdueBalance } = getClientBalance();

  return (
    <Box display='flex' pt='25px'>
      <BalanceCard status='success' title='Open' displayNumber={openBalance} />
      <BalanceCard status='alert' title='Overdue' displayNumber={overdueBalance} />
    </Box>
  );
}
