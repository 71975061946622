import {
  Box, Container, Img, Text,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { sortBy } from 'lodash';
import { Else, If, Then } from 'react-if';
import { useParams } from 'react-router';

import { ClientBalanceCards } from './components/ClientBalanceCards';
import { InvoiceStatusDisplay } from './components/InvoiceStatusDisplay';
import { StatementTable } from './components/StatementTable';
import { studioServerApi } from 'api/studioServerApi';
import PDFIcon from 'assets/icons/view-po.svg';
import SterlingSoundLogo from 'assets/sterling-sound-no-sub-header.png';
import DateComponent from 'components/DateComponent';
import LinkWithTooltip from 'components/LinkWithTooltip';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import { LinkWithButtonStyle } from 'pages/transfers/files-page/components/LinkWithButtonStyle';
import { StatementInvoice } from 'types/studio-server';
import { formatCurrency } from 'utils/formatter';

export default function ContactStatementPage(): JSX.Element {
  const { contactIdentifier } = useParams();

  const contactUnpaidInvoiceQuery = useApiQuery({
    queryKey: [contactIdentifier],
    queryFn: () => studioServerApi.GET('/api/portal/statements/{contactIdentifier}/invoices', {
      params: {
        path: {
          contactIdentifier: contactIdentifier!,
        },
      },
    }),
    enabled: !!contactIdentifier,
  });

  const columnHelper = createColumnHelper<StatementInvoice>();
  const contactStatementColumns = [
    columnHelper.display({
      id: 'InvoiceNumber',
      header: 'Invoice No.',
      cell: ({ row }) => (() => row.original.InvoiceNumber)(),
      size: 120,
    }),
    columnHelper.display({
      id: 'ArtistName',
      header: 'Artist Name',
      cell: ({ row }) => (() => row.original.ArtistName)(),
      size: 250,
    }),
    columnHelper.display({
      id: 'ProjectName',
      header: 'Project Name',
      cell: ({ row }) => (() => row.original.ProjectName)(),
      size: 250,
    }),
    columnHelper.display({
      id: 'PurchaseOrderNumber',
      header: 'Purchase Order No.',
      cell: ({ row }) => (() => row.original.PurchaseOrderNumber ?? '-')(),
      size: 190,
    }),
    columnHelper.display({
      id: 'Date',
      header: 'Date',
      cell: ({ row }) => (() => <DateComponent date={row.original.InvoiceDate} />)(),
      size: 120,
    }),
    columnHelper.display({
      id: 'Amount',
      header: 'Amount',
      cell: ({ row }) => (() => formatCurrency(row.original.Amount!))(),
      size: 120,
    }),
    columnHelper.display({
      id: 'Balance',
      header: 'Balance',
      cell: ({ row }) => (() => formatCurrency(row.original.Amount! - row.original.Payments!))(),
      size: 120,
    }),
    columnHelper.display({
      id: 'Status',
      header: 'Status',
      cell: ({ row }) => (
        () => <InvoiceStatusDisplay invoiceDate={row.original.InvoiceDate!} netDays={row.original.MajorLabel ? 60 : 30} />
      )(),
      size: 170,
    }),
    columnHelper.display({
      id: 'PayInvoice',
      cell: ({ row }) => (() => (
        <Box display='flex'>
          <LinkWithTooltip
            href={`/api/downloads/project-invoice/${row.original.InvoiceIdentifier}`}
            title='Download PDF'
            icon={<Img src={PDFIcon} display='flex' width='25px' />}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '10px',
            }}
          />
          <LinkWithButtonStyle
            href={`/portal/pay-invoice/${contactUnpaidInvoiceQuery.apiResult?.data?.Contact?.ContactIdentifier}/${row.original.InvoiceIdentifier!}/`}
            tooltip='Pay Now'
          >
            Pay
          </LinkWithButtonStyle>
        </Box>
      ))(),
      size: 205,
    }),
  ];

  return (
    <QuerySuspense queries={[contactUnpaidInvoiceQuery.queryResult]}>
      <Img src={SterlingSoundLogo} height='63px' w='258px' mt='45px' ml='50px' />
      <Container sx={{
        display: 'flex',
        flexDir: 'column',
        maxW: '1732px',
        paddingX: '94px',
        paddingY: '82px',
      }}
      >
        <Text fontSize='48px' fontWeight='600'>
          {`Hi ${contactUnpaidInvoiceQuery.apiResult?.data?.Contact?.FirstName}`}
        </Text>
        <Text fontSize='20px' color='gray.400'>
          {contactUnpaidInvoiceQuery.apiResult?.data?.Contact?.Email}
        </Text>
        <If condition={!!contactUnpaidInvoiceQuery.apiResult?.data?.Clients?.length}>
          <Then>
            {contactUnpaidInvoiceQuery.apiResult?.data?.Clients!.map((c) => (
              <Box sx={{ display: 'flex', flexDir: 'column', mt: '50px' }}>
                <Text fontSize='32px' fontWeight='600'>{c.ClientName}</Text>
                <ClientBalanceCards client={c} />
                <StatementTable
                  variant='customStriped'
                  columns={contactStatementColumns}
                  emptyTableState={{
                    message: 'No Invoices To Display',
                    icon: '',
                  }}
                  PaginationControll={null}
                  rows={{ data: sortBy(c.Invoices, ['InvoiceDate']) }}
                />
              </Box>
            ))}
          </Then>
          <Else>
            <Text
              textAlign='center'
              borderRadius='10px'
              border='2px solid #A0AEC0'
              color='gray.400'
              py='80px'
              px='50px'
              fontSize='42px'
              fontWeight='600'
              mt='50px'
            >You have no outstanding invoices.
            </Text>
          </Else>
        </If>
      </Container>
    </QuerySuspense>
  );
}
