import {
  Button,
  Flex, IconButton, Img, Spacer, Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { When } from 'react-if';
import { useLocalStorage } from 'react-use';
import { useGate } from 'statsig-react';

import QuerySuspense from './QuerySuspense';
import { transferServerApi } from 'api/transfersServerApi';
import CircleXMark from 'assets/icons/circle-xmark.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import { useApiQuery } from 'hooks/useApiQuery';
import useUserContext from 'hooks/useUserContext';

export function SmsOptInAlert(): JSX.Element {
  const { isStudioClient } = useUserContext();
  const smsOptInAlertGate = useGate('sms_opt_in_alert');
  const [hideSmsOptInAlert, setHideSmsOptInAlert] = useLocalStorage('hide-sms-opt-in-alert', false);
  const smsEnrollmentQuery = useApiQuery({
    queryKey: ['sms_opt_in_alert'],
    queryFn: () => transferServerApi.GET('/api-v2/sms/enroll'),
    enabled: !hideSmsOptInAlert,
  });
  const closeAlert = (): void => {
    setHideSmsOptInAlert(true);
  };

  // Ensure sms opt in alert is hidden if user has already opted in or out.
  useEffect(() => {
    if (!smsEnrollmentQuery.apiResult?.data || hideSmsOptInAlert) return;
    setHideSmsOptInAlert(smsEnrollmentQuery.apiResult?.data.OptIn !== null);
  }, [smsEnrollmentQuery.apiResult?.data, hideSmsOptInAlert, setHideSmsOptInAlert]);

  return (
    <When condition={isStudioClient && !hideSmsOptInAlert && smsOptInAlertGate.value}>
      <QuerySuspense queries={[smsEnrollmentQuery.queryResult]}>
        <When
          condition={smsEnrollmentQuery.apiResult?.data?.OptIn === null}
        >
          <Flex px='54px' minH='90px' w='100vw' background='gray.100' alignItems='center' justifyContent='space-between' gap='15px' p='20px'>
            <Spacer />
            <Text color='black' fontSize='15px' fontWeight='500'>
              You are receiving email alerts regarding your transfers. Keep yourself informed with SMS notifications by adjusting your subscription preferences.
            </Text>

            <Button
              as='a'
              aria-label='Subscribe'
              background='blue.400'
              size='lg'
              href='/transfers/subscriptions'
            >
              <Flex alignItems='center' gap='7px' overflow='hidden'>
                <Img w='17px' h='15px' src={PhoneIcon} />
                <Text fontSize='14px' fontWeight='600' color='white'>Subscribe now</Text>
              </Flex>
            </Button>
            <Spacer />
            <IconButton
              justifySelf='flex-end'
              aria-label='Close'
              icon={<Img h='25px' w='25px' src={CircleXMark} />}
              background='transparent'
              _hover={{ background: 'transparent' }}
              onClick={closeAlert}
            />
          </Flex>
        </When>
      </QuerySuspense>
    </When>
  );
}
