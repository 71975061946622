import { Outlet, Route } from 'react-router';

import ApproveProjectConfirmation from 'pages/approve-project-confirmation/ApproveProjectConfirmation';
import ApproveSignOffs from 'pages/approve-sign-offs/ApproveSignOffs';
import ConfirmPurchaseOrderRequest from 'pages/confirm-purchase-order-request/ConfirmPurchaseOrderRequest';
import PurchaseOrderConfirmed from 'pages/confirm-purchase-order-request/PurchaseOrderConfirmed';
import ContactStatementPage from 'pages/portal/ContactStatementPage/ContactStatementPage';
import InvoicePaid from 'pages/portal/InvoicePaid';
import PayInvoiceRedirect from 'pages/portal/PayInvoiceRedirect';

export default function usePortalRoutes(): JSX.Element {
  return (
    <Route
      path='/portal/*'
      element={(
        <Outlet />
      )}
    >
      <Route path='approve-project-confirmation/' element={<ApproveProjectConfirmation />} />
      <Route path='approve-sign-offs/' element={<ApproveSignOffs />} />
      <Route path='confirm-po-request/:purchaseOrderApprovalRequestIdentifier' element={<ConfirmPurchaseOrderRequest />} />
      <Route path='po-request-approved/:purchaseOrderApprovalRequestIdentifier' element={<PurchaseOrderConfirmed />} />
      <Route path='pay-invoice/:contactIdentifier/:projectInvoiceIdentifier/' element={<PayInvoiceRedirect />} />
      <Route path='pay-invoice/paid/:contactIdentifier/:projectInvoiceIdentifier/:checkoutSessionId?' element={<InvoicePaid />} />
      <Route path='statements/:contactIdentifier' element={<ContactStatementPage />} />
    </Route>
  );
}
